import { useState, useEffect } from 'react';


function useCoinInfos(coinId, keepRefresh) {
   const [ data, setData ] = useState([])
   const [ isLoading, setIsLoading ] = useState(false)

    //1. Import coingecko-api
    const CoinGecko = require('coingecko-api');
    
    //2. Initiate the CoinGecko API Client
    const CoinGeckoClient = new CoinGecko();
 
    useEffect(() => {
     
      async function fetchData() {
        setIsLoading(true)
        
        try {
          const result = await CoinGeckoClient.coins.fetch(coinId, {});
          setData(result.data);

          setIsLoading(false)   
        } catch(e) {
          console.log(e);
        }  
      }
 
      fetchData()

      if (keepRefresh) {
        setInterval(() => {
          fetchData()
          //  console.log('hook: fetch data');
        }, 60000);
      }
 
   }, [])

  return [data, isLoading];
}


export default useCoinInfos;