
import { useState, useEffect } from 'react';
import './../i18n';
import { useTranslation } from 'react-i18next';

function useLanguage() {
   const [ language, setLanguage ] = useState([])
   const { i18n } = useTranslation();

   useEffect(() => {
    setLanguage(i18n.language)
   }, [])

  return language;
}


export default useLanguage;