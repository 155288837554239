import React from "react";
import './Blits.scss'; 
import imageStep11 from './../../images/guides/blits/image11.png'; 
import imageStep12 from './../../images/guides/blits/image12.png'; 
import imageStep21 from './../../images/guides/blits/image21.png'; 
import imageStep22 from './../../images/guides/blits/image22.png'; 
import imageStep23 from './../../images/guides/blits/image23.png'; 
import imageStep24 from './../../images/guides/blits/image24.png'; 
import imageStep25 from './../../images/guides/blits/image25.png'; 
import imageStep26 from './../../images/guides/blits/image26.png'; 
import imageStep27 from './../../images/guides/blits/image27.png'; 
import imageStep28 from './../../images/guides/blits/image28.png'; 
import imageStep31 from './../../images/guides/blits/image31.png'; 
import imageStep32 from './../../images/guides/blits/image32.png'; 
import imageStep33 from './../../images/guides/blits/image33.png'; 
import imageStep34 from './../../images/guides/blits/image34.png'; 
import imageStep41 from './../../images/guides/blits/image41.png'; 
import imageStep42 from './../../images/guides/blits/image42.png'; 
import imageStep43 from './../../images/guides/blits/image43.png'; 
import imageStep44 from './../../images/guides/blits/image44.png'; 
import imageStep45 from './../../images/guides/blits/image45.png'; 
import imageStep46 from './../../images/guides/blits/image46.png'; 
import imageStep47 from './../../images/guides/blits/image47.png'; 
import imageStep48 from './../../images/guides/blits/image48.png'; 
import imageStep51 from './../../images/guides/blits/image51.png'; 
import imageStep52 from './../../images/guides/blits/image52.png'; 
import imageStep53 from './../../images/guides/blits/image53.png'; 
import imageStep54 from './../../images/guides/blits/image54.png'; 
import imageStep55 from './../../images/guides/blits/image55.png'; 
import { useTranslation } from 'react-i18next';

function Blits() {
  const { t } = useTranslation();
  return (
    <div className="blits">

        <h2>{t('guide.blits.title')}</h2>

        <p>
            {t('guide.blits.description')}
        </p>

        <p>
            <a href="https://blits.net/" target="_blank" rel="noreferrer">https://blits.net/</a>
        </p>

        {/* STEP 1 */}
        <h3>
            {t('guide.blits.step1.title')}
        </h3>

        <p>
            {t('guide.blits.step1.txt')}
        </p>

        <figure>
            <img src={imageStep11} alt="" />
            <figcaption>{t('guide.blits.step1.img1.caption')}</figcaption>
        </figure>


        <p>
            {t('guide.blits.step1.txt2')}
        </p>

        <p>
            {t('guide.blits.step1.txt3')}
        </p>

        <figure>
            <img src={imageStep12} alt="" />
            <figcaption>{t('guide.blits.step1.img2.caption')}</figcaption>
        </figure>


        {/* STEP 2 */}
        <h3>
            {t('guide.blits.step2.title')}
        </h3>

        <p>
            {t('guide.blits.step2.txt1')}
        </p>

        <p>
            {t('guide.blits.step2.txt2')}
        </p>

        <figure>
            <img src={imageStep21} alt="" />
            <figcaption>{t('guide.blits.step2.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step2.txt3')}
        </p>

        <figure>
            <img src={imageStep22} alt="" />
            <figcaption>{t('guide.blits.step2.img2.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step2.txt4')}
        </p>

        <figure>
            <img src={imageStep23} alt="" />
            <figcaption>{t('guide.blits.step2.img3.caption')}</figcaption>
        </figure>

        <figure>
            <img src={imageStep24} alt="" />
            <figcaption>{t('guide.blits.step2.img4.caption')}</figcaption>
        </figure>

        <figure>
            <img src={imageStep25} alt="" />
            <figcaption>{t('guide.blits.step2.img5.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step2.txt5')}
        </p>

        <figure>
            <img src={imageStep26} alt="" />
            <figcaption>{t('guide.blits.step2.img6.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step2.txt6')}
        </p>

        <figure>
            <img src={imageStep27} alt="" />
            <figcaption>{t('guide.blits.step2.img7.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step2.txt7')}
        </p>

        <figure>
            <img src={imageStep28} alt="" />
            <figcaption>{t('guide.blits.step2.img8.caption')}</figcaption>
        </figure>

        {/* STEP 3 */}
        <h3>
            {t('guide.blits.step3.title')}
        </h3>

        <p>
            {t('guide.blits.step3.txt1')}
        </p>

        <p>
            {t('guide.blits.step3.txt2')}
        </p>

        <figure>
            <img src={imageStep31} alt="" />
            <figcaption>{t('guide.blits.step3.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step3.txt3')}
        </p>
        
        <figure>
            <img src={imageStep32} alt="" />
            <figcaption>{t('guide.blits.step3.img2.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step3.txt4')}
        </p>

        <figure>
            <img src={imageStep33} alt="" />
            <figcaption>{t('guide.blits.step3.img3.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step3.txt5')}
        </p>
        <p>
            {t('guide.blits.step3.txt6')}
        </p>

        <figure>
            <img src={imageStep34} alt="" />
            <figcaption>{t('guide.blits.step3.img4.caption')}</figcaption>
        </figure>


        {/* STEP 4 */}
        <h3>
            {t('guide.blits.step4.title')}
        </h3>

        <p>
            {t('guide.blits.step4.txt1')}
        </p>

        <p>
            {t('guide.blits.step4.txt2')}
        </p>

        <figure>
            <img src={imageStep41} alt="" />
            <figcaption>{t('guide.blits.step4.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step4.txt3')}
        </p>

        <p>
            {t('guide.blits.step4.txt4')}
        </p>

        
        <figure>
            <img src={imageStep42} alt="" />
            <figcaption>{t('guide.blits.step4.img2.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step4.txt5')}
        </p>

        <figure>
            <img src={imageStep43} alt="" />
            <figcaption>{t('guide.blits.step4.img3.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step4.txt6')}
        </p>
        <p>
            {t('guide.blits.step4.txt7')}
        </p>
        <p>
            {t('guide.blits.step4.txt8')}
        </p>

        <figure>
            <img src={imageStep44} alt="" />
            <figcaption>{t('guide.blits.step4.img4.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step4.txt9')}
        </p>

        <figure>
            <img src={imageStep45} alt="" />
            <figcaption>{t('guide.blits.step4.img5.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step4.tx10')}
        </p>

        <figure>
            <img src={imageStep46} alt="" />
            <figcaption>{t('guide.blits.step4.img6.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step4.tx11')}
        </p>

        <figure>
            <img src={imageStep47} alt="" />
            <figcaption>{t('guide.blits.step4.img7.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step4.tx12')}
        </p>

        <figure>
            <img src={imageStep48} alt="" />
            <figcaption>{t('guide.blits.step4.img8.caption')}</figcaption>
        </figure>

        {/* STEP 5 */}
        <h3>
            {t('guide.blits.step5.title')}
        </h3>

        <p>
            {t('guide.blits.step5.txt1')}
        </p>
        <p>
            {t('guide.blits.step5.txt2')}
        </p>

        <figure>
            <img src={imageStep51} alt="" />
            <figcaption>{t('guide.blits.step5.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step5.txt3')}
        </p>

        <figure>
            <img src={imageStep52} alt="" />
            <figcaption>{t('guide.blits.step5.img2.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step5.txt4')}
        </p>

        <p>
            {t('guide.blits.step5.txt5')}
        </p>

        <figure>
            <img src={imageStep53} alt="" />
            <figcaption>{t('guide.blits.step5.img3.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step5.txt6')}
        </p>

        <figure>
            <img src={imageStep54} alt="" />
            <figcaption>{t('guide.blits.step5.img4.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.blits.step5.txt7')}
        </p>

        <figure>
            <img src={imageStep55} alt="" />
            <figcaption>{t('guide.blits.step5.img5.caption')}</figcaption>
        </figure>

    </div> 
  );
}


export default Blits;
