import React from "react";
import './Metamask.scss'; 
import imageStep21 from './../../images/guides/metamask/step21.png'; 
import imageStep22 from './../../images/guides/metamask/step22.png'; 
import imageStep3 from './../../images/guides/metamask/step3.png'; 
import imageStep411 from './../../images/guides/metamask/step411.png'; 
import imageStep412 from './../../images/guides/metamask/step412.png'; 
import imageStep511 from './../../images/guides/metamask/step511.png'; 
import imageStep512 from './../../images/guides/metamask/step512.png'; 
import imageStep611 from './../../images/guides/metamask/step611.png'; 
import imageStep612 from './../../images/guides/metamask/step612.png'; 
import { useTranslation } from 'react-i18next';

function Metamask() {
  const { t } = useTranslation();
  return (
    <div className="metamask">

        <h2>{t('guide.metamask.title')}</h2>

        <p>
            {t('guide.metamask.description')}
        </p>

        {/* STEP 1 */}
        <h3>
            {t('guide.metamask.step1.title')}
        </h3>
        <p>
            {t('guide.metamask.step1.txt')}
            <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank" rel="noreferrer">{t('global.here')}</a>
            {t('guide.metamask.step1.txt2')}
        </p>

        {/* STEP 2 */}
        <h3>
            {t('guide.metamask.step2.title')}
        </h3>
        <p>
            {t('guide.metamask.step2.txt')}
        </p>

        <h4>
            {t('guide.metamask.step21.title')}
        </h4>
        <p>
            {t('guide.metamask.step21.txt')}
        </p>

        <figure>
            <img src={imageStep21} alt="" />
            <figcaption>{t('guide.metamask.step21.img1.caption')}</figcaption>
        </figure>

        <h4>
            {t('guide.metamask.step22.title')}
        </h4>
        <p>
            {t('guide.metamask.step22.txt')}
        </p>
        <p className="info">
            {t('guide.metamask.step22.txt2')}
        </p>

        <div className="table-container">
            <table>
                <tr>
                    <th>{t('guide.metamask.step22.table.th1')}</th>
                    <th>{t('guide.metamask.step22.table.th2')}</th>
                    <th>{t('guide.metamask.step22.table.th3')}</th>
                </tr>
                <tr>
                    <td className="label">{t('guide.metamask.step22.table.tr1')}</td>
                    <td>Harmony Mainnet</td>
                    <td>Harmony Testnet</td>
                </tr>
                <tr>
                    <td className="label">{t('guide.metamask.step22.table.tr2')}</td>
                    <td>
                        <p>https://api.harmony.one</p>
                        <p>https://s1.api.harmony.one</p>
                        <p>https://s2.api.harmony.one</p>
                        <p>https://s3.api.harmony.one</p>
                    </td>
                    <td>
                        <p>https://api.s0.b.hmny.io</p>
                        <p>1666700001</p>
                        <p>https://api.s2.b.hmny.io</p>
                        <p>https://api.s3.b.hmny.io</p>
                    </td>
                </tr>
                <tr>
                    <td className="label">
                        <p>
                            {t('guide.metamask.step22.table.tr3')}
                        </p>
                        <p>
                            ({t('guide.metamask.step22.table.tr32')})
                        </p>
                    </td>
                    <td>
                        <p>Shard 0: 1666600000</p>
                        <p>Shard 1: 1666600001</p>
                        <p>Shard 2: 1666600002</p>
                        <p>Shard 3: 1666600003</p>
                    </td>
                    <td>
                        <p>Shard 0: 1666700000</p>
                        <p>Shard 1: 1666700001</p>
                        <p>Shard 2: 1666700002</p>
                        <p>Shard 3: 1666700003</p>
                    </td>
                </tr>
                <tr>
                    <td className="label">
                        <p>
                            {t('guide.metamask.step22.table.tr4')}
                        </p>
                        <p>
                            ({t('guide.metamask.step22.table.tr42')})
                        </p>
                    </td>
                    <td>ONE</td>
                    <td>ONE</td>
                </tr>
                <tr>
                    <td className="label">
                        <p>
                            {t('guide.metamask.step22.table.tr5')}
                        </p>
                        <p>
                            ({t('guide.metamask.step22.table.tr52')})
                        </p>
                    </td>
                    <td>
                        <a href="https://explorer.harmony.one/#/" target="_blank" rel="noreferrer">https://explorer.harmony.one/#/</a>
                    </td>
                    <td>
                        <a href="https://explorer.pops.one/#/" target="_blank" rel="noreferrer">https://explorer.pops.one/#/</a>
                    </td>
                </tr>
            </table>
        </div>
            
        {/* <p className="info">
            {t('guide.metamask.step22.txt3')}
        </p> */}
        

        <p>
            {t('guide.metamask.step22.txt4')}
        </p>

        <figure>
            <img src={imageStep22} alt="" />
        </figure>

        <p>
            {t('guide.metamask.step22.txt5')}
        </p>

        {/* STEP 3 */}
        <h3>
            {t('guide.metamask.step3.title')}
        </h3>
        <p>
            {t('guide.metamask.step3.txt')}
        </p>

        <figure>
            <img src={imageStep3} alt="" />
            <figcaption>{t('guide.metamask.step3.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.metamask.step3.txt2')}
        </p>

        {/* STEP 4 */}
        <h3>
            {t('guide.metamask.step4.title')}
        </h3>
        <h4>
            {t('guide.metamask.step41.title')}
        </h4>
        <p>
            {t('guide.metamask.step41.txt')}
        </p>

        <figure>
            <img src={imageStep411} alt="" />
        </figure>

        <p>
            {t('guide.metamask.step41.txt2')}
        </p>

        <figure>
            <img src={imageStep412} alt="" />
            <figcaption>{t('guide.metamask.step41.img2.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.metamask.step41.txt3')}
        </p>

        {/* STEP 5 */}
        <h3>
            {t('guide.metamask.step5.title')}
        </h3>

        <p className="info">
            {t('guide.metamask.step5.txt')}
        </p>

        <h4>
            {t('guide.metamask.step51.title')}
        </h4>

        <p>
            {t('guide.metamask.step51.txt')}
        </p>

        <p>
            {t('guide.metamask.step51.txt2')}
        </p>

        <figure>
            <img src={imageStep511} alt="" />
            <figcaption>{t('guide.metamask.step51.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.metamask.step51.txt3')}
        </p>

        <figure>
            <img className="wider" src={imageStep512} alt="" />
            <figcaption>{t('guide.metamask.step51.img2.caption')}</figcaption>
        </figure>

        <h4>
            {t('guide.metamask.step52.title')}
        </h4>

        <p>
            {t('guide.metamask.step52.txt')}
        </p>

        <h4>
            {t('guide.metamask.step53.title')}
        </h4>

        <p>
            {t('guide.metamask.step53.txt')}
        </p>

        
        {/* STEP 6 : HRC20/HRC721 Transactions */}
        <h3>
            {t('guide.metamask.step6.title')}
        </h3>

        <h4>
            {t('guide.metamask.step61.title')}
        </h4>
        <p>
            {t('guide.metamask.step61.txt')}
        </p>

        <figure>
            <img src={imageStep611} alt="" />
            <figcaption>{t('guide.metamask.step61.img1.caption')}</figcaption>
        </figure>

        <figure>
            <img src={imageStep612} alt="" />
            <figcaption>{t('guide.metamask.step61.img2.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.metamask.step61.txt2')}
        </p>

        <h4>
            {t('guide.metamask.step62.title')}
        </h4>
        <p>
            {t('guide.metamask.step62.txt')}
        </p>

        <h4>
            {t('guide.metamask.step63.title')}
        </h4>
        <p>
            {t('guide.metamask.step63.txt')}
        </p>

    </div> 
  );
}


export default Metamask;
