import React from "react";
import './Contact.scss';
import logoTwitter from '../images/twitter.svg';  
import arobase from '../images/arobase.png';  

function Contact() {
  return (
    <div className="page contact">
      <h1 className="main-title">Contact</h1>


      <ul>
        <li>
          <strong>Twitter </strong> <a href="https://twitter.com/OValidator" target="_blank" rel="noreferrer"><img className="logo-twitter" src={logoTwitter} alt="twitter ONE4ALL" /></a>   
        </li>
        <li>
          <strong>Telegram </strong> <a href="https://t.me/one4allvalidator" target="_blank" rel="noreferrer">https://t.me/one4allvalidator</a>
        </li>
        <li>
          <strong>Email </strong> admin<img className="arobase" src={arobase} alt="email" />one4all.one
        </li>
      </ul>

    </div>
  );
}


export default Contact;
