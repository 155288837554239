import React, {useState} from 'react';
import './Guide.scss';
import Viper from './Guides/Viper.js';
import Metamask from './Guides/Metamask.js';
import Blits from './Guides/Blits.js';
import Openswap from './Guides/Openswap.js';
import Staking from './Guides/Staking.js';
import { useTranslation } from 'react-i18next';

function Guide() {
  const { t } = useTranslation();

  const [activeGuide, setActiveGuide] = useState(
    'staking'
  ); 


  const guides = [
    {
      name: 'staking' 
    },
    {
      name: 'metamask'
    },
    {
      name: 'blits'
    },
    {
      name: 'openswap'
    },
    {
      name: 'viper' 
    }
  ];

  function showGuide(guide) {
    if (guide === activeGuide) {
      setActiveGuide('');
    } else {
      setActiveGuide(guide);
    }
  }

  function renderGuide(guide) {
    switch(guide) {
      case 'metamask':
        return <Metamask />;
      case 'viper':
        return <Viper />;
      case 'openswap':
          return <Openswap />;
      case 'blits':
        return <Blits />;
      case 'staking':
        return <Staking />;
      default:
        return '';
    }
  }

  return (
    <div className="page guide">

      <h1 className="main-title">{t('guide.title')}</h1>

      <div className="guides ">
        <div className="left-container">
          <div class="guides-move">
            {guides.map((guide, index) => (
              <div >
                <button className={`button-one4all ${activeGuide === guide.name ? 'active' : ''}`} onClick={() => { showGuide(guide.name) }}>{t(`guide.btn.${guide.name}`)}</button>
              </div>
            ))}
          </div>
        </div>

        <div className="right-container">
          {guides.map((guide, index) => (
              <div>
              {activeGuide === guide.name ?
              <div className="guide-container"> 
                {renderGuide(guide.name)}
              </div>
              : ''}
              </div>
          ))}
        </div>

      </div>

    </div>
  );
}


export default Guide;
