import React from "react";
import './Viper.scss';
// import logoViper from '../images/VIPER.png';
// import logoMochiswap from '../images/mochiswap.png';
// import logoSushiswap from '../images/sushiswap.png';  
// import logoTwitter from '../images/twitter.svg';  
import { useTranslation } from 'react-i18next';

function Viper() {
  const { t, i18n } = useTranslation();
  return (
    <div className="viper">
        <ul>
            <li>
                <strong>{t('guide.viper.guide1.title')} : </strong>
                <a href={i18n.language === 'fr' ? 'https://drive.google.com/file/d/1R-NFHRChnrZ6084NjyliKM4UR0GD3dxZ/view' : 'https://venomdao.medium.com/introducing-venomdao-viper-protocol-a-cross-chain-multi-product-defi-dao-launching-on-harmony-7ac84a7d76d3'} target="_blank" rel="noreferrer">
                    {i18n.language === 'fr' ? 'Lien google drive' : 'https://venomdao.medium.com/introducing-venomdao-viper-protocol-a-cross-chain-multi-product-defi-dao-launching-on-harmony-7ac84a7d76d3'}
                </a>
            </li>
            <li>
                <strong>{t('guide.viper.guide2.title')} : </strong>
                <a href={i18n.language === 'fr' ? 'https://drive.google.com/file/d/1YMAQLOg5uxirMFo39Zh-QXCPbvuNrpM_/view' : 'https://venomdao.medium.com/how-to-yield-farm-viper-on-harmony-1f776e7f80cb'} target="_blank" rel="noreferrer">
                    {i18n.language === 'fr' ? 'Lien google drive' : 'https://venomdao.medium.com/how-to-yield-farm-viper-on-harmony-1f776e7f80cb'}
                </a>
            </li>
        </ul>
    </div> 
  );
}


export default Viper;
