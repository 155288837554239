import React from "react";
// import ImgsViewer from "react-images-viewer";
import './Why.scss';
import { useTranslation } from 'react-i18next';

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'


import harmonyone from './../images/why/harmonyone.JPG';
import harmonyone2 from './../images/why/harmonyone2.JPG';
import harmonyone3 from './../images/why/harmonyone3.JPG';
import harmonyone4 from './../images/why/harmonyone4.jpg';
import harmonyone6 from './../images/why/harmonyone6.JPG';
import harmonyone7 from './../images/why/harmonyone7.JPG';
import harmonyone8 from './../images/why/harmonyone8.JPG';


import { Gallery, Item } from 'react-photoswipe-gallery'
 
function Why() {
  const { t } = useTranslation();

  const images = [harmonyone, harmonyone2, harmonyone3, harmonyone6, harmonyone7];
  const images50 = [harmonyone4, harmonyone8];

  const MyGallery = () => (
    <Gallery>
      <div className="gallery-container">
        {images.map((image, index) => (
          <Item
          original={image}
          thumbnail={image}
          width="1920"
          height="1280"
          >
          {({ ref, open }) => (
            <img className="gallery-thumbnail" ref={ref} onClick={open} src={image} alt=""/>
          )}
          </Item>
        ))}
      </div>

      <div className="gallery-container">
        {images50.map((image, index) => (
          <Item
          original={image}
          thumbnail={image}
          width="1920"
          height="1280"
          >
          {({ ref, open }) => (
            <img className="gallery-thumbnail gallery-thumbnail-50" ref={ref} onClick={open} src={image} alt=""/>
          )}
          </Item>
        ))}
      </div>
    </Gallery>
  )

  return (
    <div className="page why"> 

      <h1 className="main-title">{t('why.title')}</h1>
    
      <div className="title-section">{t('why.subtitle1')} :</div>
      <ul>
        <li>
          <strong>{t('why.reason1')} : </strong>{t('why.reasondetails1')}
        </li>
        <li>
          <strong>{t('why.reason2')} : </strong>{t('why.reasondetails2')}
        </li>
        <li>
          <strong>{t('why.reason3')} : </strong>{t('why.reasondetails3')}
        </li>
        <li>
          <strong>{t('why.reason4')} : </strong>{t('why.reasondetails4')}
        </li>
        <li>
          <strong>{t('why.reason5')} : </strong>{t('why.reasondetails5')}
        </li>
        <li>
          <strong>{t('why.reason6')} : </strong>{t('why.reasondetails6')}
        </li>
        <li>
          <strong>{t('why.reason7')} : </strong>{t('why.reasondetails7')}
        </li>
      </ul>

      <div className="title-section">{t('why.subtitle2')} :</div>
      <ul>
        <li>
          <strong>{t('why.morereason1')} :</strong> {t('why.morereasondetails1')}
        </li>
        <li>
          <strong>{t('why.morereason2')} :</strong> {t('why.morereasondetails2')}
        </li>
        <li>
          <strong>{t('why.morereason3')} :</strong> {t('why.morereasondetails3')}
        </li>
        <li>
          <strong>{t('why.morereason4')} :</strong> {t('why.morereasondetails4')}
        </li>
        <li>
          <strong>{t('why.morereason5')} :</strong> {t('why.morereasondetails5')}
        </li>
      </ul>
        
      <div className="gallery">
        <MyGallery></MyGallery>
      </div>


    </div>
  );
}


export default Why;
