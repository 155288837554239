import React, { useState } from 'react';
import moment from 'moment';
import 'moment/min/moment-with-locales' 
import 'moment/locale/fr';
import 'moment/locale/es';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import flagFr from './../../images/Flag_of_France.svg';
import flagEn from './../../images/Flag_of_the_United_Kingdom.svg';
import flagEsp from './../../images/Flag_of_Spain.svg';
import './../../i18n';
import { useTranslation } from 'react-i18next';

import './LangageSelector.scss';
import useLanguage from "./../../hooks/language.js"; 


const LangageSelector = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const language = useLanguage(); 

  const toggle = () => setDropdownOpen(prevState => !prevState);
  
  const { i18n } = useTranslation();


  function renderLangage(lang) {
    let lng = lang || i18n.language;
    switch(lng) {
      case 'fr':
        return <img src={flagFr} className="flag" alt="logo" />;
      case 'en':
        return <img src={flagEn} className="flag" alt="logo" />;
      case 'es':
        return <img src={flagEsp} className="flag" alt="logo" />;
      default:
        return '';
    }
  }

  
  const changeLanguage = (lng) => {
    // debugger;
    i18n.changeLanguage(lng);

    // setLanguage(lng);
    moment.locale(lng);
  }

  return (
    <Dropdown className="langage-selector" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
       {renderLangage()}
       {/* {i18n.language} */}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => changeLanguage('fr')}>
          <img src={flagFr} className="flag" alt="logo" />
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage('en')}>
           <img src={flagEn} className="flag" alt="logo" />
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage('es')}>
           <img src={flagEsp} className="flag" alt="logo" />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default LangageSelector;