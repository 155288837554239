import React from "react";
import './Staking.scss';  
import imageStep21 from './../../images/guides/staking/image21.JPG'; 
import imageStep31 from './../../images/guides/staking/image31.JPG'; 
import imageStep32 from './../../images/guides/staking/image32.JPG'; 
import imageStep33 from './../../images/guides/staking/image33.JPG'; 
import imageStep41 from './../../images/guides/staking/image41.JPG';  
import imageStep51 from './../../images/guides/staking/image51.JPG'; 
import { useTranslation } from 'react-i18next';

function Staking() {
  const { t } = useTranslation();
  return (
    <div className="staking">

        <h2>{t('guide.staking.title')}</h2>

        <p>
            {t('guide.staking.description')}
        </p>


        {/* STEP 1 */}
        <h3>
            {t('guide.staking.step1.title')}
        </h3>

        <p>
            {t('guide.staking.step1.txt')}
        </p>

        <p>
            {t('guide.staking.step1.txt2')}
        </p>

        <p>
            {t('guide.staking.step1.txt3')}
        </p>

        <p>
            {t('guide.staking.step1.txt4')}
            <a href="https://bridge.harmony.one/busd" target="_blanck" rel="noopener">https://bridge.harmony.one/busd</a>
            {t('guide.staking.step1.txt5')}
        </p>


        {/* STEP 2 */}
        <h3>
            {t('guide.staking.step2.title')}
        </h3>

        <p>
            {t('guide.staking.step2.txt1')}
        </p>

        <p>
            {t('guide.staking.step2.txt2')}
        </p>


        <figure>
            <img src={imageStep21} alt="" />
            <figcaption>{t('guide.staking.step2.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.staking.step2.txt3')}
        </p>


        {/* STEP 3 */}
        <h3>
            {t('guide.staking.step3.title')}
        </h3>

        <p>
            {t('guide.staking.step3.txt1')}
            <a href="https://staking.harmony.one/validators/mainnet" target="_blanck" rel="noopener">https://staking.harmony.one/validators/mainnet</a>
        </p>

        <figure>
            <img src={imageStep31} alt="" />
            <figcaption>{t('guide.staking.step3.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.staking.step3.txt2')}
        </p>
        
        <figure>
            <img src={imageStep32} alt="" />
            <figcaption>{t('guide.staking.step3.img2.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.staking.step3.txt3')}
        </p>

        <figure>
            <img src={imageStep33} alt="" />
            <figcaption>{t('guide.staking.step3.img3.caption')}</figcaption>
        </figure>

        {/* STEP 4 */}
        <h3>
            {t('guide.staking.step4.title')}
        </h3>

        <p>
            {t('guide.staking.step4.txt1')}
        </p>

        <figure>
            <img src={imageStep41} alt="" />
            <figcaption>{t('guide.staking.step4.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.staking.step4.txt2')}
        </p>

        <p>
            {t('guide.staking.step4.txt3')}
        </p>

        <p>
            {t('guide.staking.step4.txt4')}
        </p>

        <p>
            {t('guide.staking.step4.txt5')}
        </p>

        <p>
            {t('guide.staking.step4.txt6')}
        </p>

       
        {/* STEP 5 */}
        <h3>
            {t('guide.staking.step5.title')}
        </h3>

        <p>
            {t('guide.staking.step5.txt1')}
        </p>

        <p>
            {t('guide.staking.step5.txt2')}
            <a href="https://staking.harmony.one/validators/mainnet" target="_blanck" rel="noopener">https://staking.harmony.one/validators/mainnet</a>
            {t('guide.staking.step5.txt3')}
        </p>


        <p className="info">
            {t('guide.staking.step5.txt4')}
        </p>

        <figure>
            <img src={imageStep51} alt="" />
            <figcaption>{t('guide.staking.step5.img1.caption')}</figcaption>
        </figure>

        {/* STEP 6 */}
        <h3>
            {t('guide.staking.step6.title')}
        </h3>

        <p>
            {t('guide.staking.step6.txt1')}
        </p>
        
        <p className="info">
            {t('guide.staking.step6.txt2')}
        </p>

        {/* STEP 7 */}
        <br/>
        <br/>
        <p>
            {t('guide.staking.step7.txt1')}
            <a href="https://staking.harmony.one/validators/mainnet/one13hr88u4zjrx2rf9gkdchawppdnsrctunlxug56" target="_blanck" rel="noopener">ONE4All </a>
            {t('guide.staking.step7.txt2')}
        </p>

       
    </div> 
  );
}


export default Staking;
