import React from "react";
import './Justice.scss';
import logo from './../images/logo-40.png';
import logoPlaceholder from './../images/heroes/ph.png';
import logoTwitter from '../images/twitter.svg';  
import logoOpenFi from '../images/heroes/openfi.jpg'; 
import logoJCR from '../images/heroes/jcr.jpg';   
import logoBrother from '../images/heroes/brotherone.jpg';
import logoWebsite from '../images/icon-website-black.svg'; 
import logoGithub from '../images/github-icon.svg';
import { useTranslation } from 'react-i18next';   

function Justice() {
  const { t } = useTranslation();

  const heroes = [
    {
      name: 'OpenFi',
      logo: logoOpenFi,
      customClass: 'logo-90',
      twitter: 'https://twitter.com/open_fi',
      website: 'https://openswap.one',
      github: 'https://openswap.one',
      validator: 'https://staking.harmony.one/validators/mainnet/one1p2e0a0806jv8tqr37k7c8k67zgfjwtzpf9apv2'
    },
    {
      name: 'Jimbo JCR.one',
      logo: logoJCR,
      customClass: '',
      twitter: 'https://twitter.com/JCRacefab',
      validator: 'https://staking.harmony.one/validators/mainnet/one18r3lv27kw5ye203m0x3w58cr734wy2wqa6l8ur'
    },
    {
      name: 'BrotherOne',
      logo: logoBrother,
      customClass: '',
      twitter: 'https://twitter.com/OniiiONE',
      website: 'https://brotherone.net/',
      validator: 'https://staking.harmony.one/validators/mainnet/one1kh9xzevupc60jyw3t0r7wgsgkml8kw8w32z3h3'
    }
  ];


  return (
    <div className="page justice">

      <h1 className="main-title">{t('justice.title')}</h1>
      <p>
        {t('justice.s1')}
        <br/>
        {t('justice.s2')} <img className="logo" src={logo} alt="Logo" />
      </p>

      <div className="title-section">Heroes : </div>
      <ul>

        {heroes.map((person, index) => (
           <li key={person.name}>
            {person.logo ? <span className={`hero-logo ${person.customClass}`} style={{ backgroundImage: "url(" + person.logo + ")"}}></span> : ''}
            <span className="hero-infos">
              <strong>{person.name} :</strong>
              {person.twitter ? <a href={person.twitter} target="_blank" rel="noreferrer"><img className="logo-twitter" src={logoTwitter} alt="Twitter" /></a> : ''}
              {person.website ? <a href={person.website} target="_blank" rel="noreferrer"><img className="logo-website" src={logoWebsite} alt="Website" /></a> : ''}
              {person.github ? <a href={person.github}  target="_blank" rel="noreferrer"><img className="logo-github" src={logoGithub} alt="Github" /></a> : ''}
              {person.validator ? <a className="validator" href={person.validator} target="_blank" rel="noreferrer">{t('global.validator')}</a> : ''}
            </span>  
          </li>
        ))}

        <li>
          <img className="logo-ph" src={logoPlaceholder} alt="" /> <strong>??? :</strong>
          <img className="logo-twitter" src={logoTwitter} alt="" />     
        </li>
        <li>
          <img className="logo-ph" src={logoPlaceholder} alt="" /> <strong>??? :</strong>
          <img className="logo-twitter" src={logoTwitter} alt="" />     
        </li>
        <li>
          <img className="logo-ph" src={logoPlaceholder} alt="" /> <strong>??? :</strong>
          <img className="logo-twitter" src={logoTwitter} alt="" />     
        </li>
        <li>
          <img className="logo-ph" src={logoPlaceholder} alt="" /> <strong>??? :</strong>
          <img className="logo-twitter" src={logoTwitter} alt="" />     
        </li>
      </ul>

    </div>
  );
}


export default Justice;
