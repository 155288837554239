import React, {useState} from 'react';
import { BrowserRouter as Router, Switch, Route, NavLink } from "react-router-dom";

import LangageSelector from './components/Widgets/LangageSelector.js';
import Home from './components/Home.js';
import News from './components/News.js';
import Why from './components/Why.js';
import Ecosystem from './components/Ecosystem.js';
import Links from './components/Links.js';
import Justice from './components/Justice.js';
import Contact from './components/Contact.js';
import Guide from './components/Guide.js';
import Learn from './components/Learn.js';
import './App.scss';
// import logo from './images/logo-40.png';
import logo from './images/circle-logo-40.png';
import logoH from './images/harmony-one-logo.svg';
import {isMobile} from "react-device-detect"; 
 
import useCoinInfos from "./hooks/coinInfos.js"; 
import useLanguage from "./hooks/language.js"; 
import './i18n';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/min/moment-with-locales' 
import 'moment/locale/fr';
import 'moment/locale/es';


const App = () => {

  const [menuVisible, setMenuVisible] = useState(
    (isMobile ? false : true)
  ); 

  const [data, isLoading] = useCoinInfos('harmony', true); 

  const language = useLanguage(); 
  const { t } = useTranslation();


  function toggleMenu(e) {
    setMenuVisible(!menuVisible);
  }

  return (
    <div className="App">
        <header className="main-header">
          <div className="menu-mobile" onClick={toggleMenu}></div> 
          <img className="logo" src={logo} alt="Logo" />
          
          
          <span className="harmony-price">
            <img src={logoH} className="logo-harmony" alt="logo" />
            {isLoading ? '' : 
              <span className="harmony-infos">
               <span className="price">{data && data.market_data ? data.market_data.current_price.usd.toFixed(4) : ''}$</span> 
               <span className="rank">(<span className="rank-label">{t('app.nav.rank')}: </span>{data && data.market_data ? data.market_data.market_cap_rank : ''})</span> 
              </span> 
              }
          </span>

          <span className="stake-link">
            <a href="https://staking.harmony.one/validators/mainnet/one13hr88u4zjrx2rf9gkdchawppdnsrctunlxug56" target="_blank" rel="noreferrer">stake<span className="stake-link-one"> one</span></a>
          </span>
            
          <span className="langages">
            <LangageSelector/>
          </span>
        </header>

        <Router>
        <div>
          <nav className={`navigation ${menuVisible ? '' : 'none'}`}>
            <ul className="nav-links">
              <li className="nav-link">
                <NavLink to="/" exact onClick={isMobile && toggleMenu}>{t('app.menu.home')}</NavLink>
              </li>
              <li className="nav-link">
                <NavLink to="/news" end onClick={isMobile && toggleMenu}>{t('app.menu.news')}</NavLink>
              </li>
              <li className="nav-link">
                <NavLink to="/why" onClick={isMobile && toggleMenu}>{t('app.menu.why')}</NavLink>
              </li>
              <li className="nav-link">
                <NavLink to="/learn" onClick={isMobile && toggleMenu}>{t('app.menu.learn')}</NavLink>
              </li>
              <li className="nav-link">
                <NavLink to="/ecosystem" onClick={isMobile && toggleMenu}>{t('app.menu.ecosystem')}</NavLink>
              </li>
              <li className="nav-link">
                <NavLink to="/guide" onClick={isMobile && toggleMenu}>{t('app.menu.guide')}</NavLink>
              </li>
              <li className="nav-link">
                <NavLink to="/links" onClick={isMobile && toggleMenu}>{t('app.menu.links')}</NavLink>
              </li>
              <li className="nav-link">
                <NavLink to="/justice" onClick={isMobile && toggleMenu}>{t('app.menu.justice')}</NavLink>
              </li>
              <li className="nav-link">
                <NavLink to="/contact" onClick={isMobile && toggleMenu}>{t('app.menu.contact')}</NavLink>
              </li>
            </ul>
          </nav>

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          
          <div className="main-container">
            <Switch>
              {/* <Route path="/about">
                <About />
              </Route> */}
               <Route path="/news">
                <News />
              </Route>
              <Route path="/why">
                <Why />
              </Route>
              <Route path="/learn">
                <Learn />
              </Route>
              <Route path="/ecosystem">
                <Ecosystem />
              </Route>
              <Route path="/guide">
                <Guide />
              </Route>
              <Route path="/links">
                <Links />
              </Route>
              <Route path="/justice">
                <Justice />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/">
                <Home />
              </Route>
   
             
            </Switch>

            <div className={`menu-veil ${menuVisible && isMobile ? '' : 'none'}`}  onClick={isMobile && toggleMenu}></div>
          </div>
        </div>
      </Router>

        
      </div>

  )
}



export default App;
