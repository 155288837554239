import React from "react";
import './Links.scss';
// import logoViper from '../images/VIPER.png';
// import logoMochiswap from '../images/mochiswap.png';
// import logoSushiswap from '../images/sushiswap.png';  
// import logoTwitter from '../images/twitter.svg';  
import { useTranslation } from 'react-i18next';

function Links() {
  const { t } = useTranslation();

  const links = [
    {
      name: t('links.harmony.name'),
      url: 'https://www.harmony.one/',
      description: t('links.harmony.description')
    },
    {
      name: t('links.validators.name'),
      url: 'https://staking.harmony.one/validators/mainnet',
      description: t('links.validators.description')
    },
    {
      name: t('links.stats.name'),
      url: 'https://harmony.smartstake.io',
      description: t('links.stats.description')
    },
    {
      name: t('links.documentation.name'),
      url: 'https://docs.harmony.one/',
      description: t('links.documentation.description')
    },
    {
      name: t('links.whitepaper.name'),
      url: 'https://harmony.one/whitepaper.pdf',
      description: t('links.whitepaper.description')
    },
    {
      name: t('links.bridgeharmony.name'),
      url: 'https://bridge.harmony.one',
      description: t('links.bridgeharmony.description')
    },
    {
      name: t('links.bridgeterra.name'),
      url: 'https://bridge.terra.money',
      description: t('links.bridgeterra.description')
    },
    {
      name: t('links.anyswap.name'),
      url: 'https://anyswap.exchange/bridge#/bridge',
      description: t('links.anyswap.description')
    },
    {
      name: t('links.boringdao.name'),
      url: 'https://oportal.boringdao.com/twoway',
      description: t('links.boringdao.description')
    }
  ];

  return (
    <div className="page links">

      <h1 className="main-title">{t('links.title')}</h1>

      <ul>
        {links.map((link, index) => (
           <li>
             <strong>{link.name} </strong>
             <a href={link.url} target="_blank" rel="noreferrer">({link.url})</a>
             {link.description ? <span> : {link.description}</span> : ''}
          </li>
        ))}
        
      </ul>

    </div>
  );
}


export default Links;
