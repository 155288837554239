import React from "react";
import './Election.scss';  

import imageStep1 from './../../images/articles/election/election1.JPG'; 
import imageStep2 from './../../images/articles/election/election2.JPG'; 
import imageStep3 from './../../images/articles/election/election3.png'; 
import imageStep41 from './../../images/articles/election/election6.JPG'; 
import imageStep42 from './../../images/articles/election/election7.JPG'; 
import imageStep43 from './../../images/articles/election/overbid.JPG'; 
import imageStep44 from './../../images/articles/election/lowbid.JPG'; 
import imageStep45 from './../../images/articles/election/election5.JPG'; 
import { useTranslation } from 'react-i18next';

function Election() {
  const { t } = useTranslation(['translation', 'articles']);
  return (
    <div className="election">

        <h2>{t('articles:election.title')}</h2>

        <p class="date">
            {t('articles:election.date')}
        </p>

        <br/>

        <p>
            {t('articles:election.txt')}
            <br/>
            {t('articles:election.txt2')}
        </p>
        <p>
            
        </p>

        
        <br/>
        <p>
            {t('articles:election.txt3')}
        </p>
        <ul>
          <li>{t('articles:election.list.item1')}</li>
          <li>{t('articles:election.list.item2')}</li>
          <li>{t('articles:election.list.item3')}</li>
          <li>{t('articles:election.list.item4')}</li>
        </ul>
        <br/>

        <p>
            {t('articles:election.txt4')}
            <a href="https://staking.harmony.one/analytics/mainnet" target="_blank" rel="noreferrer">https://staking.harmony.one/analytics/mainnet</a>
            <br/>
            {t('articles:election.txt5')}
            <a href="https://harmony.smartstake.io/election" target="_blank" rel="noreferrer">https://harmony.smartstake.io/election</a>
            <br/>
            {t('articles:election.txt6')}
        </p>
        <p>
           
        </p>
        <p>
           
        </p>

        {/* SUBTITLE 1 */}
        <h3>{t('articles:election.step1.title')}</h3>

        <figure>
            <img className="full" src={imageStep1} alt="" />
            <figcaption>{t('articles:election.step1.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('articles:election.step1.txt')}
        </p>
        <p>
            {t('articles:election.step1.txt2')}
        </p>

        {/* SUBTITLE 2 */}
        <h3>{t('articles:election.step2.title')}</h3>

        <figure>
            <img className="full" src={imageStep2} alt="" />
            <figcaption>{t('articles:election.step2.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('articles:election.step2.txt')}
        </p>
        <p>
            {t('articles:election.step2.txt2')}
        </p>
        <p>
            {t('articles:election.step2.txt3')}
        </p>
        <p>
            {t('articles:election.step2.txt4')}
        </p>

        {/* SUBTITLE 3 */}
        <h3>{t('articles:election.step3.title')}</h3>

        <p>
          {t('articles:election.step3.txt')} 
           <a href="https://blog.harmony.one/introducing-harmonys-effective-proof-of-stake-epos/" target="_blank" rel="noreferrer">https://blog.harmony.one/introducing-harmonys-effective-proof-of-stake-epos/</a>
        </p>
        <p>
          {t('articles:election.step3.txt2')}
        </p>

        <figure>
          <img src={imageStep3} alt="" />
          <figcaption>{t('articles:election.step3.img1.caption')}</figcaption>
        </figure>

       
        <p>
          {t('articles:election.step3.txt3')}
        </p>

        {/* SUBTITLE 4 */}
        <h3>{t('articles:election.step4.title')}</h3>

        <figure>
          <img className="full" src={imageStep41} alt="" />
          <figcaption>{t('articles:election.step4.img1.caption')}</figcaption>
        </figure>

        <p>
          {t('articles:election.step4.txt')}
        </p>
        <p>
          {t('articles:election.step4.txt2')}
        </p>

        <p>
          {t('articles:election.step4.txt3')}
        </p>

        <figure>
          <img className="small" src={imageStep42} alt="" />
          <figcaption>{t('articles:election.step4.img2.caption')}</figcaption>
        </figure>

        <p>
          {t('articles:election.step4.txt4')}
        </p>


        <h4>
            {t('articles:election.step41.title')}
        </h4>

        <p>
          {t('articles:election.step41.txt')}
        </p>

        <figure>
          <img className="small" src={imageStep43} alt="" />
          <figcaption>{t('articles:election.step41.img1.caption')}</figcaption>
        </figure>

        <p className="info">
            {t('articles:election.step41.txt2')}
        </p>


        <h4>
            {t('articles:election.step42.title')}
        </h4>

        <p>
          {t('articles:election.step42.txt')}
        </p>

        <figure>
          <img className="small" src={imageStep44} alt="" />
          <figcaption>{t('articles:election.step42.img1.caption')}</figcaption>
        </figure>

        <p className="info">
            {t('articles:election.step42.txt2')}
        </p>

        <p>
          {t('articles:election.step4.txt5')}
        </p>

        <figure>
          <img className="small" src={imageStep45} alt="" />
          <figcaption>{t('articles:election.step4.img3.caption')}</figcaption>
        </figure>

        <p className="info">
          {t('articles:election.step4.txt6')}
        </p> 

        <br/>
        <p>
          {t('articles:election.step4.txt7')}
        </p>

        <ul>
          <li>{t('articles:election.step4.list.item1')}</li>
          <li>{t('articles:election.step4.list.item2')}</li>
          <li>{t('articles:election.step4.list.item3')}</li>
          <li>{t('articles:election.step4.list.item4')}</li>
          <li>{t('articles:election.step4.list.item5')}</li>
          <li>{t('articles:election.step4.list.item6')}</li>
          <li>{t('articles:election.step4.list.item7')}</li>
          <li>{t('articles:election.step4.list.item8')}</li>
        </ul>

        <br/>

        {/* SUBTITLE 5 */}
        <h3>{t('articles:election.step5.title')}</h3>

        <p>
          {t('articles:election.step5.txt')}
        </p>
        <p>
          {t('articles:election.step5.txt2')}
        </p>


        {/* CONCLUSION */}
        <br/>
        <p className="italic">
          {t('global.tyforreading')}  <span className="stake-link">
            <a href="https://staking.harmony.one/validators/mainnet/one13hr88u4zjrx2rf9gkdchawppdnsrctunlxug56" target="_blank" rel="noreferrer">{t('global.here')}</a>.
          </span>
        </p>
    </div> 
  );
}


export default Election;
