import React from "react";
import logoH from '../images/harmony-one-logo.svg';
// import logo from '../images/logo-pouce-circle.png';

import logo from '../images/circle-logo.png'; 
import loading from '../images/loading.gif'; 

import './Home.scss';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Timeline } from 'react-twitter-widgets'
import useValidatorInfos from "../hooks/validator.js"; 


import globalVariables from '../Global'

function Home() {

  const { t } = useTranslation();
  const [data, isLoading] = useValidatorInfos(globalVariables.validatorAddress); 

  function calculateDelegators(delegations) {
    let nbDelegators = 0;
 
    // let test = delegations.filter((value, index, self) => self.map(x => x['delegator-address']).indexOf(value['delegator-address']) == index)

    let test = delegations.filter((d) => {
      return d.amount > 0
    });

    nbDelegators = test.length;

    return nbDelegators;
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="page home"> 
      <p className="harmony-container">
        <strong className="powered">{t('home.powered')}</strong>
        <a href="https://www.harmony.one/" target="_blank" rel="noreferrer"><img src={logoH} className="logo-harmony" alt="logo" /></a>
      </p>

      <img src={logo} className="logo-one4all" alt="logo" />
      <h1 className="title">
        ONE4All
      </h1>
      <p className="subtitle">(et tous pour one)</p>
      <br/>
      <p className="informations">
        <a href="https://staking.harmony.one/validators/mainnet/one13hr88u4zjrx2rf9gkdchawppdnsrctunlxug56" target="_blank" rel="noreferrer">ONE4All {t('global.validator')}</a>

        <div className="stats-container">
          {isLoading ? <div className="loading"><img src={loading} alt="Loading..." /></div> : 
              <div className="stats">
                {/* <span className={`stat active ${data['active-status'] === 'active' ? 'is-active' : ''}`}>{data['active-status']}</span> & <span className={`stat elected ${data['epos-status'] === 'currently elected' ? 'is-elected' : ''}`}>{data['epos-status']}</span> */}
                
                {data ?
                <div>
                  <div>
                    <div className="stat stake">{numberWithCommas(Math.round(data['total-delegation'] / 1000000000000000000))}</div>
                    <div className="stat stake-label">{t('home.totalStake')}</div>
                  </div>
                  
                  
                  <div> 
                    <div className="stat delegators">{data.validator && data.validator.delegations && calculateDelegators(data.validator.delegations)}</div>
                    <div className="stat delegators-label">{t('home.delegators')}</div>
                  </div>
                </div>
                : <div>...</div>}
              </div> 
          }
        </div>
        

        <div>one13hr88u4zjrx2rf9gkdchawppdnsrctunlxug56</div>

        <div className="staking-help">
          {t('home.stakinghelp')} 
          <Link to="/guide">{t('guide.staking.title')}</Link>
        </div>
      </p>
      <p className="description">
      {t('home.s1')}
        <br/>{t('home.s2')}
        <br/>{t('home.s3')}
      </p>

      <div className="twitter-timeline">
        <Timeline
        
          dataSource={{
            sourceType: 'profile',
            screenName: 'OValidator'
          }}
          options={{
            height: '400'
          }}
        />
      </div>

    </div>
  );
}


export default Home;
