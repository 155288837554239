import React from "react";
import './Openswap.scss'; 
import imageStep11 from './../../images/guides/openswap/image11.png'; 
import imageStep12 from './../../images/guides/openswap/image12.png'; 
import imageStep13 from './../../images/guides/openswap/image13.png'; 
import imageStep14 from './../../images/guides/openswap/image14.png'; 
import imageStep15 from './../../images/guides/openswap/image15.png'; 
import imageStep16 from './../../images/guides/openswap/image16.png'; 
import imageStep21 from './../../images/guides/openswap/image21.png'; 
import imageStep22 from './../../images/guides/openswap/image22.png'; 
import imageStep23 from './../../images/guides/openswap/image23.png'; 
import imageStep31 from './../../images/guides/openswap/image31.png'; 
import imageStep32 from './../../images/guides/openswap/image32.png'; 
import imageStep33 from './../../images/guides/openswap/image33.png'; 
import imageStep34 from './../../images/guides/openswap/image34.png'; 
import imageStep41 from './../../images/guides/openswap/image41.png'; 
import imageStep42 from './../../images/guides/openswap/image42.png'; 
import imageStep43 from './../../images/guides/openswap/image43.png'; 
import imageStep44 from './../../images/guides/openswap/image44.png'; 
import imageStep45 from './../../images/guides/openswap/image45.png'; 
import { useTranslation } from 'react-i18next';

function Openswap() {
  const { t } = useTranslation();
  return (
    <div className="openswap">

        <h2>{t('guide.openswap.title')}</h2>

        <p>
            {t('guide.openswap.description')}
        </p>


        {/* STEP 1 */}
        <h3>
            {t('guide.openswap.step1.title')}
        </h3>

        <p>
            {t('guide.openswap.step1.txt')}
        </p>

        <p>
            {t('guide.openswap.step1.txt2')}
        </p>

        <figure>
            <img src={imageStep11} alt="" />
            <figcaption>{t('guide.openswap.step1.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step1.txt3')}
        </p>


        <figure>
            <img src={imageStep12} alt="" />
            <figcaption>{t('guide.openswap.step1.img2.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step1.txt4')}
        </p>

        <figure>
            <img src={imageStep13} alt="" />
            <figcaption>{t('guide.openswap.step1.img3.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step1.txt5')}
        </p>

        <figure>
            <img src={imageStep14} alt="" />
            <figcaption>{t('guide.openswap.step1.img4.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step1.txt6')}
        </p>

        <figure>
            <img src={imageStep15} alt="" />
            <figcaption>{t('guide.openswap.step1.img5.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step1.txt7')}
        </p>

        <figure>
            <img src={imageStep16} alt="" />
            <figcaption>{t('guide.openswap.step1.img6.caption')}</figcaption>
        </figure>

        {/* STEP 2 */}
        <h3>
            {t('guide.openswap.step2.title')}
        </h3>

        <p>
            {t('guide.openswap.step2.txt1')}
        </p>

        <p>
            {t('guide.openswap.step2.txt2')}
        </p>

        <p>
            {t('guide.openswap.step2.txt3')}
        </p>

        <figure>
            <img src={imageStep21} alt="" />
            <figcaption>{t('guide.openswap.step2.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step2.txt4')}
        </p>

        <p>
            {t('guide.openswap.step2.txt5')}
        </p>

        <figure>
            <img src={imageStep22} alt="" />
            <figcaption>{t('guide.openswap.step2.img2.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step2.txt6')}
        </p>

        <figure>
            <img src={imageStep23} alt="" />
            <figcaption>{t('guide.openswap.step2.img3.caption')}</figcaption>
        </figure>


        {/* STEP 3 */}
        <h3>
            {t('guide.openswap.step3.title')}
        </h3>

        <p>
            {t('guide.openswap.step3.txt1')}
        </p>

        <p>
            {t('guide.openswap.step3.txt2')}
        </p>

        <figure>
            <img src={imageStep31} alt="" />
            <figcaption>{t('guide.openswap.step3.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step3.txt3')}
        </p>
        
        <figure>
            <img src={imageStep32} alt="" />
            <figcaption>{t('guide.openswap.step3.img2.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step3.txt4')}
        </p>

        <figure>
            <img src={imageStep33} alt="" />
            <figcaption>{t('guide.openswap.step3.img3.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step3.txt5')}
        </p>

        <figure>
            <img src={imageStep34} alt="" />
            <figcaption>{t('guide.openswap.step3.img4.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step3.txt6')}
        </p>


        {/* STEP 4 */}
        <h3>
            {t('guide.openswap.step4.title')}
        </h3>

        <p>
            {t('guide.openswap.step4.txt1')}
        </p>

        <p>
            {t('guide.openswap.step4.txt2')}
        </p>

        <p>
            {t('guide.openswap.step4.txt3')}
        </p>

        <figure>
            <img src={imageStep41} alt="" />
            <figcaption>{t('guide.openswap.step4.img1.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step4.txt4')}
        </p>

        <figure>
            <img src={imageStep42} alt="" />
            <figcaption>{t('guide.openswap.step4.img2.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step4.txt5')}
        </p>

        <p className="info">
            {t('guide.openswap.step4.txt6')}
        </p>

        <p className="info">
            {t('guide.openswap.step4.txt7')}
        </p>

        <figure>
            <img src={imageStep43} alt="" />
            <figcaption>{t('guide.openswap.step4.img3.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step4.txt8')}
        </p>

        <figure>
            <img src={imageStep44} alt="" />
            <figcaption>{t('guide.openswap.step4.img4.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step4.txt9')}
        </p>

        <figure>
            <img src={imageStep45} alt="" />
            <figcaption>{t('guide.openswap.step4.img5.caption')}</figcaption>
        </figure>

        <p>
            {t('guide.openswap.step4.tx10')}
        </p>

        <p className="info">
            {t('guide.openswap.step4.tx11')}
        </p>

    </div> 
  );
}


export default Openswap;
