import axios from "axios";
import { useState, useEffect } from 'react';
import globalVariables from '../Global'

function useValidatorInfos(validatorAddress) {
   const [ data, setData ] = useState([])
   const [ isLoading, setIsLoading ] = useState(false)
 
    useEffect(() => {
  
      async function fetchData() {

        setIsLoading(true)
 
        const result = await axios.post(
          globalVariables.rpcHarmony,
          { 
            "jsonrpc": "2.0",
            "id": 1,
            "method": "hmyv2_getValidatorInformation",
            "params": [
                validatorAddress   
            ]
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        // console.log(result.data.result);
        setData(result.data.result);
        setIsLoading(false)     
      }
 
      fetchData()
 
   }, [])

  return [data, isLoading];
}


export default useValidatorInfos;