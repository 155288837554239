import React, {useState} from 'react';
import Moment from 'react-moment';
import moment from 'moment';
// import ImgsViewer from "react-images-viewer";
import './News.scss';
import { useTranslation } from 'react-i18next';

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import logoHound from '../images/hound-validator.png';
// import logo from '../images/circle-thumb-no-margin.png';
import logo from '../images/circle-logo-40-2.png';
import logoH from '../images/harmony-one-logo.svg';

import { TextField } from '@material-ui/core';




import { Gallery, Item } from 'react-photoswipe-gallery'
 
function News() {
  const { t, i18n } = useTranslation(["translation", "news"]);
  const [searchTerm, setSearchTerm] = useState(
    ""
  ); 
 
  let myNews = []; 
  let reorganizedNews = [];
  let nbNews = 170; 


  function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }

  function initNews() {

    for (let i = 0; i < nbNews; i++) {
      const active = t(`news:${i+1}.active`);
      if (active === "true") {
        var res = getWeekNumber(new Date(t(`news:${i+1}.rawdate`)));

        const nouvelleDate = moment(t(`news:${i+1}.rawdate`));

        // by default the week is from sunday to saturday => change from monday to sunday
        // temp fix
        let subtractDays = 1;
        if (i18n.language === 'fr' || i18n.language === 'es') {
          subtractDays = 0;
        }
        const startWeek = nouvelleDate.utc().subtract(subtractDays, "days").startOf('week').add(1, "days").format('YYYY-MM-DD');


        let oneNews = {
          id: i + 1,
          visible: true,
          rawdate:  t(`news:${i+1}.rawdate`),
          // date: t(`news:${i+1}.date`),
          title: t(`news:${i+1}.title`), 
          txt: t(`news:${i+1}.txt`),
          url: t(`news:${i+1}.url`),
          reference: t(`news:${i+1}.reference`),
          image: t(`news:${i+1}.image`),
          yearweek: `${res[0]}-${res[1]}`,
          startweek: startWeek
        };

        if (t(`news:${i+1}.url2`).indexOf('.url2') === -1) {
          oneNews.url2 = t(`news:${i+1}.url2`)
        }

        myNews.push(oneNews);
      }
    }

    // sort by newest week
    myNews.sort(function(a, b) {
      if (a.rawdate < b.rawdate) {
        return -1;
      }
      return 1;
    });
  }


  function organizeNews() {
    reorganizedNews = []
    for (let i = 0; i < myNews.length; i++ ) {
      let startweek = myNews[i].startweek;
      let groupNewsForThatWeek = reorganizedNews.filter(weekNews => {
        return weekNews.startweek === startweek
      })
      if (!groupNewsForThatWeek || groupNewsForThatWeek.length === 0) {
        // take end of the week to find the proper year
        let year = (i18n.language === 'fr' || i18n.language === 'es' ? moment(startweek).add(7, 'days').format('YYYY') : moment(startweek).add(6, 'days').format('YYYY'))
        let week = (i18n.language === 'fr' || i18n.language === 'es' ? (moment(startweek).week() + 1) % 52 : moment(startweek).week())
        console.log((moment(startweek).week() + 1) % 52);
        reorganizedNews.push({
          startweek,
          year,
          week,
          list: [
            {
              rawdate: myNews[i].rawdate,
              sublist: [myNews[i]]
            }
            
          ]
        })
      } else {
        let groupNewsForThatDay = groupNewsForThatWeek[0].list.filter(day => {
          return day.rawdate === myNews[i].rawdate
        })
        
        if (!groupNewsForThatDay || groupNewsForThatDay.length === 0) {
          groupNewsForThatWeek[0].list.push({
            rawdate: myNews[i].rawdate,
            sublist: [myNews[i]]
          })
        } else {
          groupNewsForThatDay[0].sublist.push(myNews[i]);
        }

      }
      
    }

    // sort by newest week
    reorganizedNews.sort(function(a, b) {
      if (a.startweek > b.startweek) {
        return -1;
      }
      return 1;
    });

  }


  // function searchChange(event) {
  //   setSearchTerm(event.target.value);

  //   filterNews(event.target.value);
  // }

  // function filterNews(search) {
  //   console.log('filter')
  //   let searchLow = search.toLowerCase()
  //   myNews = myNews.filter(n => {
  //     if (n.title.toLowerCase().indexOf(searchLow) > -1 || n.txt.toLowerCase().indexOf(searchLow) > -1 ) {
  //       console.log('match', n)
  //       return n;
  //     } 
  //     return null;
  //   });

  //   organizeNews()
  //   setReorgNews(reorganizedNews);

  // }

  initNews()

  organizeNews()

  // const [reorgNews, setReorgNews] = useState(
  //   reorganizedNews
  // ); 

  return (
    <div className="page news"> 

      <h1 className="main-title">{t('news:title')}</h1>

      {/* <div className="search-section">
        <form className="search-form" noValidate autoComplete="off">
          <TextField id="standard-basic" label={t('global.search')} onChange={searchChange} />
        </form>
      </div> */}

  
      {reorganizedNews.length === 0 ? <div className="noResults">{t('global.noresultsfound')}</div> : reorganizedNews.map((group, index) => (
        <div key={group.startweek} className="news-week">
          <div className="news-header">
            <div className="news-logos">
              <div><img src={logoH} className="logo-harmony" alt="logo" /></div>
              <div className="news-date-range">
                <Moment className="start-week" format="Do MMM">
                  {i18n.language === 'fr' || i18n.language === 'es' ? moment(group.startweek).subtract(1, 'days') : group.startweek}
                </Moment> 
                 - 
                <Moment className="end-week" format="Do MMM" >
                  {i18n.language === 'fr' || i18n.language === 'es' ? moment(group.startweek).subtract(1, 'days').add(6, 'days') : moment(group.startweek).add(6, 'days')}
                </Moment> 

                <div className="news-yearweek">[{group.year} - {t('global.weekAbbreviation')}{group.week}]</div>
              </div>
            </div>
            
            <div className="news-logos">
              <div><img src={logoHound} className="logo-hound" alt="logo hound validator" /></div>
              <div className="logo-separator">x</div>
              <div><img src={logo} className="logo" alt="logo one4all validator" /></div>
            </div>
          </div>

          {group.list && group.list.map((day, index) => (
            <div key={day.rawdate} className="news-day">
              <div className="news-day-container">
                <Moment className="day-label" format="dddd Do">
                  {day.rawdate}
                </Moment>
              </div>
              <div className="news-day-details">
                {day.sublist && day.sublist.map((nouvelle, index) => (
                  <div key={nouvelle.id} className="news-details-container">
                    {!nouvelle.visible ? '' :
                      <div  >
                        <div className="date">{nouvelle.date}</div>
                        <div className="titleDesc">
                          {!nouvelle.title ? '' : 
                            <span className="title">{nouvelle.title}</span>
                          }
                          {!nouvelle.txt ? '' : 
                            <span className="description">{nouvelle.title ? ' : ' : ''}{nouvelle.txt}</span>
                          }
                        </div>
                        
                        {!nouvelle.url ? '' : 
                          <div className="url">
                            <a href={nouvelle.url} target="_blank" rel="noreferrer">{nouvelle.url}</a>
                          </div>
                        }

                        {!nouvelle.url2 ? '' : 
                          <div className="url">
                            <a href={nouvelle.url2} target="_blank" rel="noreferrer">{nouvelle.url2}</a>
                          </div>
                        }
                        {!nouvelle.reference ? '' : 
                          <div className="reference">
                            <span className="source">{t('global.source')}:</span> <a href={nouvelle.reference} target="_blank" rel="noreferrer">{nouvelle.reference}</a>
                          </div>
                        }
                      </div>
                    }
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}



    </div>
  );
}


export default News;
