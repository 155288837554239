import React, {useState} from 'react';
import './Learn.scss';
import Election from './Articles/Election.js';
import { useTranslation } from 'react-i18next';

function Learn() {
  const { t } = useTranslation();

  const [activeArticle, setActiveArticle] = useState(
    'election'
  ); 


  const articles = [
    {
      name: 'election' 
    }
  ];

  function showArticle(article) {
    if (article === activeArticle) {
      setActiveArticle('');
    } else {
      setActiveArticle(article);
    }
  }

  function renderArticle(article) {
    switch(article) {
      case 'election':
        return <Election />;
      default:
        return '';
    }
  }

  return (
    <div className="page article">

      <h1 className="main-title">{t('articles:title')}</h1>

      <div className="articles ">
        <div className="left-container">
          <div class="articles-move">
            {articles.map((article, index) => (
              <div >
                <button className={`button-one4all ${activeArticle === article.name ? 'active' : ''}`} onClick={() => { showArticle(article.name) }}>{t(`articles:btn.${article.name}`)}</button>
              </div>
            ))}
          </div>
        </div>

        <div className="right-container">
          {articles.map((article, index) => (
              <div>
              {activeArticle === article.name ?
              <div className="article-container"> 
                {renderArticle(article.name)}
              </div>
              : ''}
              </div>
          ))}
        </div>

      </div>

    </div>
  );
}


export default Learn;
