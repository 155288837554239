import React from "react";
import './Ecosystem.scss';
import logoOpenSwap from '../images/openswap.ico'; 
import logoViper from '../images/VIPER.png';
import logoMochiswap from '../images/mochiswap.png';
import logoDaikiri from '../images/daikiri.jpg';
import logoLootswap from '../images/lootswap.png';
import logoSushiswap from '../images/sushiswap.png'; 
import logoDavinci from '../images/davinci.png';  
import logoDfk from '../images/dfk.png';   
import logoTwitter from '../images/twitter.svg';  
import logoTelegram from '../images/telegram.png';  
import logoGithub from '../images/github-icon.svg';
import logoDiscord from '../images/discord.png';

import logoStats from '../images/stats.svg';
import { useTranslation } from 'react-i18next';


import useCoinInfos from "./../hooks/coinInfos.js"; 


function Ecosystem() {
  const { t } = useTranslation();
  const [dataViper] = useCoinInfos('viper'); 
  const [dataMochi] = useCoinInfos('mochiswap-token'); 
  const [dataSushi] = useCoinInfos('sushi'); 
  const [dataVinci] = useCoinInfos('vinci'); 
  const [dataJewel] = useCoinInfos('defi-kingdoms'); 
 
  const dexes = [
    {
      name: 'OpenSwap',
      logo: logoOpenSwap,
      customClass: '',
      twitter: 'https://twitter.com/open_fi',
      website: 'https://openswap.one/',
      telegram: 'https://t.me/OpenSwap_Harmony',
      github: 'https://github.com/TheOpenFinanceProject',
      stats: ''
    },
    {
      name: 'Viper',
      id: 'viper',
      logo: logoViper,
      customClass: '',
      twitter: 'https://twitter.com/VenomDAO',
      website: 'https://viper.exchange/',
      telegram: 'https://t.me/VenomDAO',
      discord: 'https://discord.com/invite/MgcuMNAJtP',
      github: 'https://github.com/VenomProtocol',
      stats: 'https://info.viper.exchange/'
      // price: (dataViper && dataViper.market_data ? dataViper.market_data.current_price.usd.toFixed(4) : '')
    },
    {
      name: 'Mochiswap',
      id: 'mochi',
      logo: logoMochiswap,
      customClass: '',
      twitter: 'https://twitter.com/mochiswap',
      website: 'https://one.mochiswap.io/',
      telegram: 'https://t.me/mochiswap',
      github: 'https://github.com/mochiswap',
      stats: ''
    },
    {
      name: 'Daikiri Finance',
      logo: logoDaikiri,
      customClass: '',
      twitter: 'https://twitter.com/DaikiriFinance',
      website: 'https://daikiri.finance/',
      telegram: 'https://t.me/daikiri_finance',
      github: 'https://github.com/daikirifinance',
      stats: ''
    },
    {
      name: 'LootSwap',
      logo: logoLootswap,
      customClass: '',
      twitter: 'https://twitter.com/lootswapfinance',
      website: 'https://lootswap.finance/',
      telegram: 'https://t.me/lootswap',
      discord: 'http://discord.gg/nz82UvJBpN',
      github: 'https://github.com/LootSwap',
      stats: ''
    },
    {
      name: 'Sushiswap',
      id: 'sushi',
      logo: logoSushiswap,
      customClass: '',
      twitter: 'https://twitter.com/SushiSwap',
      website: 'https://app.sushi.com/',
      telegram: 'https://t.me/sushiswap',
      discord: 'https://discord.gg/CD2YdZzb7Z',
      github: 'https://github.com/sushiswap',
      stats: ''
    },
    {
      name: 'Defi Kingdoms',
      id: 'dfk',
      logo: logoDfk,
      customClass: '',
      twitter: 'https://twitter.com/DefiKingdoms',
      website: 'https://defikingdoms.com/',
      telegram: 'https://t.me/defikingdomsannouncements',
      discord: 'https://discord.gg/kARBQuMAhS',
      github: 'https://github.com/DefiKingdoms',
      stats: 'https://info.defikingdoms.com/'
    }
  ];

  const nfts = [
    {
      name: 'daVinci',
      id: 'vinci',
      logo: logoDavinci,
      customClass: '',
      twitter: 'https://twitter.com/davinci_gallery',
      website: 'https://davinci.gallery/',
      telegram: 'https://t.me/daVinci_NFT_Marketplace',
      github: '',
      stats: ''
    }
    // {
    //   name: 'LMA Art Gallery',
    //   logo: logoDavinci,
    //   customClass: '',
    //   twitter: '',
    //   website: 'https://lma-art-gallery.com/',
    //   telegram: '',
    //   github: '',
    //   stats: ''
    // }
  ]

  function renderPrice(item) {
    let useData = null;
    if (item === 'viper') {
      useData = dataViper;
    } else if (item === 'mochi') {
      useData = dataMochi;
    } else if (item === 'sushi') {
      useData = dataSushi;
    } else if (item === 'vinci') {
      useData = dataVinci;
    } else if (item === 'dfk') {
      useData = dataJewel;
    }

    if (useData) {
      return <span className="price">{useData.market_data ? `${useData.market_data.current_price.usd.toFixed(2)}$` : ''}</span>;
    }
    return '';
  }

  function renderTVL(item) {
    let useData = null;
    let tvl = '';
    if (item === 'viper') {
      useData = dataViper;
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    if (useData && useData.market_data && useData.market_data.total_value_locked) {
      tvl = formatter.format(useData.market_data.total_value_locked.usd);
      return <span className="tvl"><span className="tvl-label">TVL: </span>{tvl}</span>;
    }

    return '';
  }


  return (
    <div className="page ecosystem">

      <h1 className="main-title">{t('ecosystem.title')}</h1>

      <div className="ecosystem-cat">
        <div className="title-section">{t('ecosystem.subtitle1')} : </div>

        <ul>
          {dexes.map((dex, index) => (
            <li>
              <img className="logo-eco" src={dex.logo} alt={dex.name} />
              <a className="eco-name" href={dex.website} target="_blank"  rel="noreferrer">{dex.name}</a>  
              <span className="social-links-icons">
                {dex.twitter ? <a href={dex.twitter} target="_blank" rel="noreferrer"><img className="logo-social logo-twitter" src={logoTwitter} title={`Twitter ${dex.name}`} alt={`twitter ${dex.name}`} /></a> : ''}
                {dex.telegram ? <a href={dex.telegram} target="_blank" rel="noreferrer"><img className="logo-social logo-telegram" src={logoTelegram} title={`Telegram ${dex.name}`} alt={`telegram ${dex.name}`} /></a> : ''}
                {dex.github ? <a href={dex.github} target="_blank" rel="noreferrer"><img className="logo-social logo-github" src={logoGithub} title={`Github ${dex.name}`} alt={`github ${dex.name}`} /></a> : ''}
                {dex.discord ? <a href={dex.discord} target="_blank" rel="noreferrer"><img className="logo-social logo-discord" src={logoDiscord} title={`Discord ${dex.name}`} alt={`discord ${dex.name}`} /></a> : ''}
                {/* {listData[dex.name] ? <span className="price">{listData[dex.name].market_data ? listData[dex.name].market_data.current_price.usd.toFixed(2) : ''}$</span> : ''} */}
              </span>
              {renderPrice(dex.id)}
              {renderTVL(dex.id)}
              {dex.stats ? <a href={dex.stats} target="_blank" rel="noreferrer"><img className="logo-social logo-stats" src={logoStats} title={`Stats ${dex.name}`} alt={`stats ${dex.name}`} /></a> : ''}
            </li>
          ))}
        </ul>
      </div>


      <div className="ecosystem-cat">            
        <div className="title-section">{t('ecosystem.subtitle2')} : </div>

        <ul>
          {nfts.map((nft, index) => (
            <li>
              <img className="logo-eco" src={nft.logo} alt={nft.name} />
              <a className="eco-name" href={nft.website} target="_blank"  rel="noreferrer">{nft.name}</a> 
              <span className="social-links-icons">
                {nft.twitter ? <a href={nft.twitter} target="_blank" rel="noreferrer"><img className="logo-social logo-twitter" src={logoTwitter} title={`Twitter ${nft.name}`} alt={`twitter ${nft.name}`} /></a> : ''}
                {nft.telegram ? <a href={nft.telegram} target="_blank" rel="noreferrer"><img className="logo-social logo-telegram" src={logoTelegram} title={`Telegram ${nft.name}`} alt={`telegram ${nft.name}`} /></a> : ''}
                {nft.github ? <a href={nft.github} target="_blank" rel="noreferrer"><img className="logo-social logo-github" src={logoGithub} title={`Github ${nft.name}`} alt={`github ${nft.name}`} /></a> : ''}
              </span>
              {renderPrice(nft.id)}
            </li>
          ))}
        </ul>
      </div>
      

    </div>
  );
}


export default Ecosystem;
